<template>
	<w-dialog v-model="dialog" error max-width="400" :title="$t('bill-scrapper.an_error_occured')">
		<w-layout v-text="errorMessage" />
	</w-dialog>
</template>

<script>
import BillScrapperModuleGuard from '@/mixins/ModulesGuards/BillScrapper/BillScrapperModuleGuard'

export default {
	name: 'ErrorDialog',
	mixins: [BillScrapperModuleGuard],
	data: function () {
		return {
			dialog: false,
			errorMessage: ''
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [{ event: this.events.SHOW_ERROR, action: this.onErrorOccured }]
		},
		onErrorOccured: function (errorMessage) {
			this.errorMessage = errorMessage
			this.dialog = true
		}
	}
}
</script>
